import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation Login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        username
        email
        name
      }
    }
  }
`;

export const REGISTER = gql`
  mutation Register($input: UsersPermissionsRegisterInput!) {
    register(input: $input) {
      jwt
      user {
        username
        email
        name
      }
    }
  }
`;

export const DELETE_WORKOUT = gql`
  mutation DeleteWorkout($workoutId: ID!) {
    deleteWorkout(id: $workoutId) {
      data {
        id
      }
    }
  }
`;

export const CREATE_WORKOUT = gql`
  mutation createWorkout($workoutInput: WorkoutInput!) {
    createWorkout(data: $workoutInput) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_WORKOUT = gql`
  mutation updateWorkout($workoutInput: WorkoutInput!, $workoutId: ID!) {
    updateWorkout(data: $workoutInput, id: $workoutId) {
      data {
        id
      }
    }
  }
`;

export const CREATE_GPT = gql`
  mutation CreateGpt($gptInput: GptInput!) {
    createGpt(data: $gptInput) {
      data {
        gptResponse
      }
    }
  }
`;
