import { gql } from '@apollo/client';

export const GET_ME = gql`
  query Me {
    me {
      id
      username
      email
      name
    }
  }
`;

export const GET_WORKOUTS = gql`
  query Workouts {
    workouts {
      data {
        id
        attributes {
          name
          description
          image
          authorEmail
          motivation
          exercises
        }
      }
    }
  }
`;

export const GET_BANNER = gql`
  query Banner {
    banner {
      data {
        attributes {
          banner
        }
      }
    }
  }
`;
